import React, { ReactElement } from 'react';
import { Wrapper, Image, ListContainer } from './ImageList.styled';
import { useTranslation } from 'react-i18next';
import { UnorderedList } from '@/components/shared/UnorderedList';
import Dots from 'static/icons/dots6.svg';

interface ImageListProps {
    image: string;
    imageAlt: string;
    title: string;
    items: string[];
}

export function ImageList({
    image,
    imageAlt,
    title,
    items,
}: ImageListProps): ReactElement {
    const { t } = useTranslation();
    return (
        <Wrapper>
            <img src={Dots} className="dots" alt="" />
            <Image>
                <h3>{title}</h3>
                <img src={image} alt={imageAlt} className="m-auto" />
            </Image>
            <ListContainer>
                <h3>{title}</h3>
                <UnorderedList>
                    {items.map((item) => (
                        <li>{item}</li>
                    ))}
                </UnorderedList>
            </ListContainer>
        </Wrapper>
    );
}
