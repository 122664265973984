import styled from 'styled-components';
import tw, { theme } from 'twin.macro';

export const Wrapper = styled.div`
    ${tw`mt-6 lg:mt-64 mb-12 lg:mb-32 gap-4 lg:gap-0 grid lg:grid-cols-2 relative`}

    .dots {
        position: absolute;
        z-index: -10;
        bottom: -20px;
        left: -60px;

        @media (max-width: ${theme`screens.lg`}) {
            display: none;
        }
    }
`;

export const Image = styled.div`
    h3 {
        ${tw`text-3xl mb-10 mt-6`}

        @media (min-width: ${theme`screens.lg`}) {
            display: none;
        }
    }
`;

export const ListContainer = styled.div`
    h3 {
        ${tw`text-3xl mb-10`}

        @media (max-width: ${theme`screens.lg`}) {
            display: none;
        }
    }

    ul {
        gap: 20px;
    }
`;
