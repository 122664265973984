import React, { useEffect, useState } from 'react';
import { AboutUs } from '@/components/AboutUs';
import { Hero } from '@/components/Hero';
import { TechnicalSolution } from '@/components/TechnicalSolution';
import { ContactForm } from '@/components/ContactForm';
import Seo from '@/components/seo.js';
import { usePageContext } from '@/contexts/PageContext';
import { useWindowScroll } from 'react-use';
import EFRELogo from 'static/images/EFRE_EU_logo.jpg';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import Img from 'static/images/TechHero.png';
import { ImageWrap, Image } from '@/components/Hero/Hero.styled';
import { ImageList } from '@/components/ImageList';
import MacbookImg from 'static/images/macbook2.png';
import SolutionImgEn from 'static/images/solution-atom-en.png';
import SolutionImgPl from 'static/images/solution-atom-pl.png';
import HealthcareSectorsImgEn from 'static/images/HealthcareSectors-en.png';
import HealthcareSectorsImgPl from 'static/images/HealthcareSectors-pl.png';
import HL7Icon from 'static/images/HL7Icon.png';
import i18n from '@/i18n/i18n';

const ForTechnologyPartnersPage = ({ path }) => {
    const { setActiveNavItem } = usePageContext();
    const scrollRef1 = React.useRef(null);
    const scrollRef2 = React.useRef(null);
    const scrollRef3 = React.useRef(null);
    const scrollRef4 = React.useRef(null);
    const scrollRef5 = React.useRef(null);
    const { y } = useWindowScroll();
    const { t } = useTranslation();

    useEffect(() => {
        if (scrollRef1.current) {
            const yy = y + 200;
            yy < scrollRef2.current.offsetTop && setActiveNavItem('home');
            yy < scrollRef3.current.offsetTop &&
                yy > scrollRef2.current.offsetTop &&
                setActiveNavItem('problem');
            yy < scrollRef4.current.offsetTop &&
                yy > scrollRef3.current.offsetTop &&
                setActiveNavItem('solution');
            yy < scrollRef5.current.offsetTop &&
                yy > scrollRef4.current.offsetTop &&
                setActiveNavItem('aboutUs');
            yy > scrollRef5.current.offsetTop && setActiveNavItem('contact');
        }
    }, [y, setActiveNavItem]);
    const isPolish = i18n.language === 'pl';
    const SolutionImg = isPolish ? SolutionImgPl : SolutionImgEn;
    const HealthcareSectorsImg = isPolish
        ? HealthcareSectorsImgPl
        : HealthcareSectorsImgEn;

    return (
        <>
            <Seo title="Medrefer" />
            <section id="hero" ref={scrollRef1}>
                <Hero
                    title={t('techPartners.heroTitle')}
                    image={
                        <motion.div>
                            <ImageWrap noButton>
                                <Image src={Img} alt={t('hero.imgAlt')} />
                            </ImageWrap>
                        </motion.div>
                    }
                    activeButton="technology-partners"
                />
            </section>
            <section id="problem" ref={scrollRef2}>
                <ImageList
                    image={MacbookImg}
                    imageAlt={t('schedulingModule.imgAlt')}
                    title={t('schedulingModule.heading')}
                    items={[
                        t('schedulingModule.item1'),
                        t('schedulingModule.item2'),
                        t('schedulingModule.item3'),
                        t('schedulingModule.item4'),
                    ]}
                />
                <TechnicalSolution
                    title={t('virtualBusiness.heading')}
                    subtitle={t('virtualBusiness.subheading')}
                    items={[
                        t('virtualBusiness.item1'),
                        t('virtualBusiness.item2'),
                        t('virtualBusiness.item3'),
                        t('virtualBusiness.item4'),
                    ]}
                    img={SolutionImg}
                    imgAlt={t('virtualBusiness.imgAlt')}
                    unorderedList
                />
            </section>
            <section id="solution" ref={scrollRef3}>
                <TechnicalSolution
                    title={t('healthcareSectors.heading')}
                    items={[
                        t('healthcareSectors.item1'),
                        t('healthcareSectors.item2', { imgSrc: HL7Icon }),
                        t('healthcareSectors.item3'),
                        t('healthcareSectors.item4'),
                    ]}
                    img={HealthcareSectorsImg}
                    imgAlt={t('healthcareSectors.imgAlt')}
                    listAlt
                />
            </section>
            <section id="aboutUs" ref={scrollRef4}>
                <AboutUs />
            </section>
            <section id="contact" ref={scrollRef5}>
                <ContactForm />
            </section>
            <section id="euro-logo">
                <div className="efre-logo-container">
                    <img src={EFRELogo} alt="EFRE EU Logo" />
                </div>
            </section>
        </>
    );
};

export default ForTechnologyPartnersPage;
